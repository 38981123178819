import { useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { useHistory } from 'react-router-dom'
import Template from '../components/Template'
import Button from '../components/button'
import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { DEFAULT_API_CONFIG } from 'models'
import { buildHeader } from 'services'
import { User } from 'models/auth'
import { Contributor } from 'models/contributors'
import { format } from 'date-fns'

type Props = {
  user: User
  contributor: Contributor
}

export default function TermsAndConditions(props: Props) {
  const [styles, setStyles] = useStyles({
    page: 'components/auth',
  })

  const getTACStatus = useQuery([], async () => {
    const uri = `/api/cms/v1/vtuber_tac_statuses?contributor_id=${props.contributor.id}`
    const instance = axios.create({
      ...DEFAULT_API_CONFIG,
    })
    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    return response.data
  })

  const signInWithApproval = useMutation(
    async () => {
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      await instance.put(
        `/api/cms/v1/vtuber_tac_statuses/${getTACStatus.data.vtuber_tac_status.id}`,
        {
          status: 'approved',
          approved_at: format(new Date(), 'yyyy/MM/dd HH:mm'),
        },
        {
          headers: buildHeader(),
        }
      )
    },
    {
      onSettled: () => {
        location.href = `/contributors/${props.contributor.id}/messages`
      },
    }
  )

  if (!styles) return <></>

  return (
    <section
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        translate: '-50% -50%',
      }}
    >
      <Template label={'利用規約'} styles={styles}>
        <section className={styles.default.terms_and_condition_text}>
          <p>CMS の利用を開始するには、以下の利用規約に同意してください。</p>
        </section>
        <label htmlFor="terms_and_conditions">
          <a
            href="https://dearst.notion.site/1123ca5882138071b64feafae1a75362"
            target="_blank"
          >
            利用規約
          </a>
        </label>
        <div className={styles.default.form_submit_btn_spacer} />
        <Button
          label="同意して利用する"
          disabled={false}
          onClickHandler={() => {
            signInWithApproval.mutate()
          }}
        />
      </Template>
    </section>
  )
}
